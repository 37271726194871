@import "@/assets/scss/var.scss";
.address {
    &-add {
        // position: absolute;
        // bottom: 100%;
        // right: 0;
    }
    .odList {
        font-size: 14px;
        margin-top: 20px;
        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .header {
            position: relative;

            > span {
                color: #999;
            }
            > .btn {
                padding: 8px 12px;
                display: inline-block;
                text-align: center;
                font-size: 14px;
                line-height: 1.5;
                border: 1px solid transparent;
                background: transparent;
                cursor: pointer;
                background: $btn-color-primary;
                color: #fff;
                width: auto;
                margin-right: 10px;
                position: absolute;
                bottom: 100%;
                right: 0;
                margin-bottom: 15px;
            }
            .item {
                margin: 20px 0;
                padding-top: 20px;
                border-top: 1px solid #f7f8fa;
                display: flex;
                .l {
                    flex: 1;
                    ul {
                        li {
                            .label {
                                display: inline-block;
                                min-width: 80px;
                                color: #999;
                            }
                        }
                        li + li {
                            margin-top: 10px;
                        }
                    }
                }
                .r {
                    display: flex;
                    align-items: center;
                    > div {
                        cursor: pointer;
                        .icon {
                            width: 1.25em;
                            height: 1.25em;
                            fill: currentColor;
                            overflow: hidden;
                            vertical-align: middle;
                        }
                    }
                    .btn {
                        padding: 4px 12px;
                        border: 1px solid transparent;
                        background: transparent;
                        cursor: pointer;
                        background: #fff;
                        border-color: $border-primary;
                        color: $color-primary;
                        height: 20px;
                        width: auto;
                    }
                    div + div {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}
